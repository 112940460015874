import React from 'react';

import SEO from '../components/seo';
import { Helmet } from 'react-helmet';
import favicon from '../images/favicon_io/favicon.ico';

const NotFoundPage = () => (
  <div>
    <Helmet>
      {/* https://favicon.io/favicon-generator/?t=H&ff=Itim&fs=110&fc=%23FFFFFF&b=rounded&bc=%2317a2b8 */}
      <link rel="icon" href={favicon} />
    </Helmet>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
);

export default NotFoundPage;
